import React from 'react'

import Container from '../Container'

import * as S from './styled'

const FooterData = () => {
  return (
    <S.FooterData>
      <Container>
        ©2020 seixas.dev
        <a className="link" href="/policies">Política de Privacidade</a>
        <a className="link" href="https://github.com/Seixas" target="_blank" rel="noopener noreferrer">Termos de Uso</a>
      </Container>
    </S.FooterData>
  )
}

export default FooterData