import styled from 'styled-components'
import media from "styled-media-query"

import * as V from '../../styles/variables'

export const LayoutWrapper = styled.section`
  display: flex;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`

export const LayoutMain = styled.main`
  //background: var(--background);
  min-height: 87vh;
  /*padding: 0 3.75rem 0 20rem;*/
  transition: background, color 0.5s;
  width: 100%;
  margin-bottom: ${V.Height.footer};
  margin-top: ${V.Height.headerSm};
  padding-bottom: ${V.Space.xxlg};
  padding-top: ${V.Height.mainTop};

  body#grid & {
    grid-template-areas:
      "posts"
      "pagination";
  }

  ${media.lessThan("medium")`
    padding: 4.125rem 0 3rem 0;
    margin-top: ${V.Height.mainTop};
  `}
`