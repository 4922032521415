import styled from 'styled-components'
import media from "styled-media-query"

import * as V from '../../styles/variables'

export const Footer = styled.footer`
  align-items: center;
  background-color: var(--primaryColor);
  //border-top: ${V.Border.default};
  bottom: 0;
  box-shadow: 2px 3px 4px var(--boxShadow);
  color: ${V.Color.white};
  display: flex;
  height: ${V.Height.footer};
  justify-content: center;
  left: 0;
  padding-left: ${V.Space.default};
  padding-right: ${V.Space.default};
  position: fixed;
  right: 0;
  z-index: ${V.ZIndex.footer};
`

export const ToTopWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 2rem;
  flex-direction: row;
`

export const ItemWrapped = styled.span`
  color: var(--texts);
  cursor: pointer;
  display: block;
  height: 3.75rem;
  padding: 1.1rem;
  position: relative;
  width: 3.75rem;
  
  &.light {
    color: #d4d400;
    
    &:hover {
      color: #e2e240;
    }
  }

  &:hover {
    color: var(--greetings);
  }

  &.display {
    ${media.lessThan("medium")`
      display: none;
    `}
  }
  ${media.greaterThan("large")`
    &:hover {
      color: var(--greetings);
    }
  `}
  ${media.lessThan("medium")`
    height: 3.2rem;
    padding: .9rem;
    position: relative;
    width: 3.2rem;
  `}
`