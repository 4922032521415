import React from 'react'
import ReactGA from 'react-ga'

import AniLink from 'gatsby-plugin-transition-link/AniLink'

import getThemeColor from "../../utils/getThemeColor"

import menuLinks from './content'
import * as S from './styled'

const trackClick = (item) => {
  ReactGA.event({
    category: 'Menu',
    action: 'click',
    label: `Menu - ${item}`
  })
}

const Menu = () => {
  return (
    <S.Menu>
      {menuLinks.map((link, i) => (
        <AniLink
          key={i}
          cover
          bg={getThemeColor()}
          direction="up"
          duration={.5}
          to={link.url}
          onClick={() => trackClick(link.label)}
          activeClassName="active">
            {link.label}
        </AniLink>
      ))}
    </S.Menu>
  )
}

export default Menu