const menuLinks = [
  {
    label: 'blog',
    url: '/blog/'
  },
  {
    label: 'hi',
    url: '/about/'
  }
]
/*,
  {
    label: 'lab',
    url: '/lab/'
  } 
*/
export default menuLinks