import styled from 'styled-components'
import media from "styled-media-query"
import Img from 'gatsby-image'

export const  AvatarWrapper = styled(Img)`
  border-radius: 50%;
  //clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%); //SAP like polygon
  //border-radius: .25rem .1rem .1rem .25rem;
  border: 2px solid var(--borders);
  height: 3.875rem;
  margin: auto;
  width: 3.875rem;

  ${media.lessThan("medium")`
    height: 2.875rem;
    width: 2.875rem;
  `}
`