import styled from 'styled-components';
import media from "styled-media-query"

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--background);
  height: 100vh;
  text-align: center;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  
  ${media.lessThan('medium')`
    width: 100%;
  `}

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: var(--shellText);
    text-decoration: none;
    transition: color 0.3s linear;
    transform: translateX(0%);
    
    ${media.lessThan('medium')` //TODO
      font-size: 1.5rem;
      text-align: center;
    `}

    &:hover {
      color: var(--greetings);
    }
  }
`

export const StyledMenuLight = styled.span`
  color: var(--shellText);
  cursor: pointer;
  display: block;
  height: 3.75rem;
  padding: 1.1rem;
  position: absolute;
  width: 3.75rem;
  top: 1%;
  
  &.light {
    color: #d4d400;
    
    &:hover {
      color: #222;
    }
  }

  &:hover {
    color: var(--greetings);
  }
`