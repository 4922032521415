import React from 'react'

import { UpArrowAlt as Arrow } from "@styled-icons/boxicons-regular/UpArrowAlt"

import Social from '../Social'

import * as S from './styled'

const Footer = () => {
  return (
    <S.Footer>
      <Social />
      <S.ToTopWrapper>
        <S.ItemWrapped
          title="Ir para o Topo"
          onClick={() => {
            window.scroll({ top: 0, behavior: 'smooth' })
          }}>
          <Arrow />
        </S.ItemWrapped>
      </S.ToTopWrapper>
    </S.Footer>
  )
}

export default Footer