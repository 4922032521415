import { createGlobalStyle } from "styled-components"

import Regular72woff  from '../../static/fonts/72/72-Regular.woff';
import Regular72woff2 from '../../static/fonts/72/72-Regular.woff2';
import Regular72ttf   from '../../static/fonts/72/72-Regular.ttf';

import * as V from './variables'

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
  }

  /*Selft hosted fonts */
  @font-face {
    font-family: '72';
    src: local('72'), local('72'),
          url(${Regular72woff}) format('woff'),
          url(${Regular72woff2}) format('woff2'),
          url(${Regular72ttf}) format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: fallback; /* <- this can be added to each @font-face definition to prevent reloading */
  }

  body {
    background: var(--background); /*change here the base bg color*/
    line-height: 1;
    font-size: 100%;
    min-height: calc(100vh);
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "kern";
    -webkit-font-kerning: normal;
    font-kerning: normal;
    //font-family:  Poppins,sofia-pro,Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif;
    font-family: Roboto,"Poppins","Reem Kufi","San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    transition: background-color ${V.Transition.default};
    will-change: background-color;
  }
  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  .small,
    small {
      font-size: .8em;
  }

  html {
    font-size: 10.5px;
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
  }
  b, strong {
    font-weight: 500;
  }
  i, em {
    font-style: italic;
  }
  abbr {
    border-bottom: 1px dotted;
  }

  /* dark n light theme css vars definitions
    here is global, so the best place to define
    as our root */
  body.dark {
    /*--borders: #38444d;
    --texts: #8899a6;
    --postColor: #fff;
    --highlight: #1fa1f2;
    --mediumBackground: #192734;
    --background: #16202c;
    --backgroundAlpha: #16202c00;
    --white: #fff;
    --black: #222;
    --postColor: #fff;
    --postTitle: #FFF;
    --postDescription: #EEE;
    --postCategory: #EEE;
    --PostItemBackground: #324255;
    --postHightlight: #2e9fff;
    --postHover: #2e9fff;*/
    --borders: #38444d;
    --texts: #8899a6;
    --postColor: #fff;
    --highlight: #1fa1f2;
    --mediumBackground: #192734;
    --background: ${V.Color.darksap_shellBgBase};
    --backgroundAlpha: #16202c00;
    --white: #fff;
    --black: #222;
    --postColor: #fff;
    --postTitle: #FFF;
    --postDescription: #EEE;
    --postCategory: #EEE;
    --PostItemBackground: #324255;
    --postHightlight: #2e9fff;
    --postHover: #2e9fff;
    --primaryColor: ${V.Color.darksap_baseContainer};
    --shellText: ${V.Color.darksap_textNTitles};
    --headerShell: ${V.Color.darksap_baseContainer};
    --headerShellAlpha: ${V.Color.darksap_baseContainerAlpha};
    --borderColor: ${V.Color.darksap_bordersNControls};
    --highlightColor: ${V.Color.lightsap_highlight};
    --menuHover: ${V.Color.blueLight};
    --disabledIcon: #FAFAFA66;
    --boxShadow: rgba(250, 250, 250, .12);
    --greetings: #FFCC68;
    --postImgShadow: rgba(200, 200, 200, 0.5);
  }
  body.light {
    --borders: #dedede;
    --postColor: #111;
    --texts: #555555;
    --highlight: #1fa1f2;
    --mediumBackground: #f0f0f3;
    --background: ${V.Color.lightsap_shellBgBase};
    --backgroundAlpha: #ffffff00;
    --white: #fff;
    --black: #222;
    --postColor: #111;
    --postTitle: #000;
    --postDescription: #555;
    --postCategory: #000;
    --PostItemBackground: #FFF;
    --postHightlight: #111;
    --postHover: #2e9fff;
    --primaryColor: ${V.Color.lightsap_baseContainer};
    --shellText: ${V.Color.lightsap_textNTitles};
    --headerShell: ${V.Color.belizesap_top_light};
    --headerShellAlpha: ${V.Color.belizesap_top_lightAlpha};
    --borderColor: ${V.Color.darksap_bordersNControls};
    --highlightColor: ${V.Color.lightsap_highlight};
    --menuHover: ${V.Color.lightsap_highlight};
    --disabledIcon: #32363A66;
    --boxShadow: rgba(18, 18, 18, .12);
    --greetings: #b54909;
    --postImgShadow: rgba(2, 2, 2, 0.7);
  }
`
export default GlobalStyles