import React, { useState, useEffect, useRef } from "react"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Bulb as Light } from "@styled-icons/boxicons-regular/Bulb"
import { SearchAlt2 as Search } from "@styled-icons/boxicons-regular/SearchAlt2"

import Menu from "../Menu"
import Container from '../Container'
import Avatar from "../avatar"
import Burger from '../Burger'
import BurgerMenu from '../BurgerMenu'
import getThemeColor from "../../utils/getThemeColor"
import * as S from './styled'
import { useOnClickOutside } from '../../hooks';

const Header = () => {
  const [theme, setTheme] = useState(null)
  const [open, setOpen] = useState(false)
  const isDarkMode = theme === "dark"

  const node = useRef(); 
  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    setTheme(window.__theme)
    window.__onThemeChange = () => setTheme(window.__theme)
  }, [])

  const onChange = () => {
    window.__setPreferredTheme(isDarkMode ? 'light' : 'dark')
    //window.__setTheme(isDarkMode ? 'light' : 'dark')

    if (window && window.DISQUS !== undefined) {
      window.setTimeout(() => window.DISQUS.reset({ reload: true }), 600)
    }
  }

  return (
    <S.Header>
      <Container>
        <S.Main>
          <AniLink
            cover
            bg={getThemeColor()}
            direction="down"
            duration={1}
            to="/"
            title="Voltar para home">
            <S.Brand>
              <Avatar />
              <S.Title className="blinker">Seixas</S.Title>
            </S.Brand>
          </AniLink>
          <S.Menu>
            <Menu />
            <S.MenuBarLink
              to="/search/"
              cover
              direction="right"
              bg={getThemeColor()}
              duration={0.3}
              title="Pesquisar"
            >
              <S.MenuBarItem>
                <Search />
              </S.MenuBarItem>
            </S.MenuBarLink>
            <S.MenuBarItem 
              title="Mudar o tema" 
              onClick={onChange}
              className={theme}
            >
              <Light />
            </S.MenuBarItem>
          </S.Menu>
          <S.MenuHamb>
            <Burger open={open} setOpen={setOpen} />
            <BurgerMenu open={open} setOpen={setOpen} />
          </S.MenuHamb>
        </S.Main>
      </Container>
    </S.Header>
  )
}

export default Header