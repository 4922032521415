// ==================================================
// 1. Spaces
// 2. Colors
// 3. zIndex
// 4. Misc
// ==================================================

// --------------------------------------------------
// 1. Spaces
// --------------------------------------------------

export const Space = {
  xxs: `.4rem`,
  xs: `.8rem`,
  sm: `1.6rem`,
  default: `2.4rem`,
  md: `3.2rem`,
  lg: `4.8rem`,
  xlg: `6.4rem`,
  xxlg: `9.6rem`,
}

export const Height = {
  headerSm: `${Space.lg}`,
  headerLg: `${Space.xlg}`,
  footer: `4rem`,
  mainTop: `${Space.md}`
}

// --------------------------------------------------
// 2. Colors
// --------------------------------------------------

export const Color = {
  white:        `rgba(255, 255, 255, .9)`,
  black:        `rgba(15, 15, 15, .95)`,
  whiteSolid:   `#fff`,
  blackSolid:   `#000`,
  blue:         `#187888`,
  blueLight:    `#a1ecf7`,
  grayDarker:   `#1d1d1d`,
  grayDark:     `#2d2d2d`,
  gray:         `#757575`,
  grayLight:    `#aaaaaa`,
  grayLighter:  `#efefef`,
  grayWhite:    `#f1f1f1`,
  yellow:       `#e6af05`,
  /*personal*/
  border:       `#e6af05`,
  texts:        `#e6af05`,
  highlight:    `#1fa1f2`,
  blueHover:    `#2e9fff`,
  backG:        `#16202c`,
  PostDesc:     `#EEE`,
  cardBg:       `#324255`,
  /* Fiori design guidelines
      SAP Fiori Standard Theme Primary Colors */
  sapShellBaseP1:                 `#3F5161` /*`rgb( 63,81,97 )`*/,
  sapBrandHighlightP2:            `#427CAC` /*`rgb( 66,124,172 )`*/,
  sapConstrastHighlightBasedOnP2: `#91C8F6` /*`rgb( 145,200,246 )`*/,
  sapGlobalLightBaseP3:           `#EFF4F9` /*`rgb( 239,244/249 )`*/,
  sapContainersP4:                `#FFFFFF` /*`rgb( 255,255,255 )`*/,
  sapAppContentBgP5:              `#FAFAFA` /*`rgb( 250,250,250 )`*/,
  sapBordersDerivedControlsP6:    `#BFBFBF` /*`rgb( 191,191,191 )`*/,
  sapTitlesAndTextsP7:            `#333333` /*`rgb( 51,51,51 )`*/,
  /* Accent colors to accentuate important elements */
  sapAccLightBrown: `#E09D00` /*`rgb( 224,157,0 )`*/,
  sapAccOrange:     `#E6600D` /*`rgb( 230/96/13 )`*/,
  sapAccBordeau:    `#C14646` /*`rgb( 193/70/70 )`*/,
  sapAccPurple:     `#AB218E` /*`rgb( 171/33/142 )`*/,
  sapAccDarkCyan:   `#678BC7` /*`rgb( 103/139/199 )`*/,
  sapAccCyan:       `#0092D1` /*`rgb( 0/146/209 )`*/,
  sapAccTeal:       `#1A9898` /*`rgb( 26 152 152 )`*/,
  sapAccDarkGreen:  `#759421` /*`rgb( 117/148/33 )`*/,
  sapAccLilac:      `#925ACE` /*`rgb( 146/90/206 )`*/,
  sapAccDarkTeal:   `#647987` /*`rgb( 100/121/135 )`*/,
  /* grayscale */
  sapGS1: `#333333` /*`rgb( 51/51/51 )`*/,
  sapGS2: `#666666` /*`rgb( 102/102/102 )`*/,
  sapGS3: `#BFBFBF` /*`rgb( 191/191/191 )`*/,
  sapGS4: `#CCCCCC` /*`rgb( 204/204/204 )`*/,
  sapGS5: `#E5E5E5` /*`rgb( 229/229/229 )`*/,
  sapGS6: `#FFFFFF` /*`rgb( 255/255/255 )`*/,
  /* semantic colors - 
    light flavor values for light theme*/
  sapNegativeLight:     `#BB0000` /*`rgb( 187/0/0 )`*/,
  sapCriticalLight:     `#E78C07` /*`rgb( 231/140/7 )`*/,
  sapPositiveLight:     `#2B7D2B` /*`rgb( 43/125/43 )`*/,
  sapNeutralLight:      `#5E696E` /*`rgb( 94/105/110)`*/,
  sapInformationLight:  `#427CAC` /*`rgb( 66/124/172 )`*/,
  /* semantic colors - 
    dark flavor values for dark theme*/
  sapNegativeDark:    `#FF8888` /*`rgb( 255/136/136 )`*/,
  sapCriticalDark:    `#FABD64` /*`rgb( 250/189/100 )`*/,
  sapPositiveDark:    `#ABE2AB` /*`rgb( 171/226/171 )`*/,
  sapNeutralDark:     `#D3D7D9` /*`rgb( 211/215/217)`*/,
  sapInformationDark: `#91c8f6` /*`rgb( 145/200/246 )`*/,

  /*Quartz dark */
  darksap_shellHeader:    `#354a5f` ,
  darksap_brandLinks:     `#91C8F6` ,
  darksap_baseContainer:  `#29313A` ,
  darksap_baseContainerAlpha:  `#29313AEE` ,
  darksap_shellBgBase:    `#232A31` ,
  darksap_bordersNControls:    `#8696A9` ,
  darksap_textNTitles:    `#FAFAFA` ,
  darksap_subtitlesNLabels:    `#D3D7D9` ,

  /*Quartz light */
  lightsap_shellHeader:    `#354a5f` ,
  lightsap_brandLinks:     `#0a6ed1` ,
  lightsap_highlight:     `#0854A0` ,
  lightsap_baseContainer:  `#ffffff` ,
  lightsap_shellBgBase:    `#edeff0` ,
  lightsap_bordersNControls:    `#89919a` ,
  lightsap_textNTitles:    `#32363a` ,
  lightsap_subtitlesNLabels:    `#6a6d70` ,

  /*Belize theme sap */
  belizesap_top_contrast: `#2C4E6C`,
  belizesap_bottom_contrast: `#9EC7D8`,
  belizesap_top_light: `#A9C6DE`,
  belizesap_top_lightAlpha: `#A9C6DEEE`,
  belizesap_bottom_light: `#E7ECF0`,

  /*sap Indication Colors for tags 
  https://experience.sap.com/fiori-design-web/quartz-dark-colors/ */
  sapUI_1: `#FF5555`,
  sapUI_2: `#FF8888`,
  sapUI_3: `#FABD64`,
  sapUI_4: `#ABE2AB`,
  sapUI_5: `#91C8F6`,
}

// --------------------------------------------------
// 3. zIndex
// --------------------------------------------------

export const ZIndex = {
  bg: `-1`,
  default: `1`,
  footer: `5`,
  header: `5`,
}

// --------------------------------------------------
// 4. Misc
// --------------------------------------------------

export const Transition = {
  default: `.25s`,
}

export const BorderRadius = {
  box: `10px`,
}

export const Border = {
  box: `1px solid var(--borderColor)`,
  default: `1px solid var(--borderColor)`,
}

export const BoxShadow = {
  box: `0 1px 3px var(--boxShadow)`,
}

export const MaxSize = {
  md: `750px`,
  lg: `1080px`,
}