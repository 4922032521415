import React, { useState, useEffect } from "react"
import ReactGA from 'react-ga'

import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { bool } from 'prop-types';
import { Bulb as Light } from "@styled-icons/boxicons-regular/Bulb"

import menuLinks from '../Menu/content'
import getThemeColor from "../../utils/getThemeColor"
import * as S from './styled'

const BurgerMenu = ({ open, ...props }) => {
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  const [theme, setTheme] = useState(null)
  const isDarkMode = theme === "dark"

  useEffect(() => {
    setTheme(window.__theme)
    window.__onThemeChange = () => setTheme(window.__theme)
  }, [])

  const onChange = () => {
    window.__setPreferredTheme(isDarkMode ? 'light' : 'dark')
    //window.__setTheme(isDarkMode ? 'light' : 'dark')

    if (window && window.DISQUS !== undefined) {
      window.setTimeout(() => window.DISQUS.reset({ reload: true }), 600)
    }
  }

  const trackClick = (item) => {
    ReactGA.event({
      category: 'BurgerMenu',
      action: 'click',
      label: `BurgerMenu - ${item}`
    })
  }

  return (
    <S.StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <S.StyledMenuLight 
              title="Mudar o tema" 
              onClick={onChange}
              className={theme}
      >
        <Light />
      </S.StyledMenuLight>
      {menuLinks.map((link, i) => (
        <AniLink
          key={i}
          cover
          bg={getThemeColor()}
          direction="up"
          duration={.5}
          to={link.url}
          onClick={() => trackClick(link.label)}
          activeClassName="active"
          tabIndex={tabIndex}>
            <span aria-hidden="true">🙋‍♂️</span>
            {link.label}
        </AniLink>
      ))}
      <AniLink
          cover
          bg={getThemeColor()}
          direction="up"
          duration={.5}
          to="/search/"
          onClick={() => trackClick("/search/")}
          activeClassName="active"
          tabIndex={tabIndex}>
            <span aria-hidden="true">🔎</span>
            Procurar
      </AniLink>
    </S.StyledMenu>
  )
}
BurgerMenu.propTypes = {
  open: bool.isRequired,
}
export default BurgerMenu;