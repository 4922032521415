import styled from 'styled-components'
import media from 'styled-media-query'

import AniLink from "gatsby-plugin-transition-link/AniLink"

import * as V from '../../styles/variables'

export const Header = styled.header`
  background-color: var(--headerShellAlpha);
  color: ${V.Color.darksap_textNTitles};
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  text-transform: lowercase;
  top: 0;
  transition: background-color .3s;
  width: 100%;
  will-change: background-color;
  z-index: ${V.ZIndex.header};

  ${media.greaterThan('medium')`
    background-color: var(--headerShellAlpha);
  `}
`

export const Main = styled.div`
  align-items: center;
  display: flex;
  height: ${V.Height.headerSm};
  justify-content: space-between;

  ${media.greaterThan('medium')`
    height: ${V.Height.headerLg};
  `}
`

export const Brand = styled.div`
  align-items: center;
  display: flex;

  > *:not(:last-child) {
    margin-right: ${V.Space.sm};
  }
`

export const Menu = styled.div`
  align-items: center;
  display: flex;

  ${media.lessThan('medium')` //TODO
    margin-right: -${V.Space.xs};
    display: none;
  `}
  > *:not(:last-child) {
    margin-right: ${V.Space.xs};
  }
`

export const MenuHamb = styled.div`
  align-items: center;
  display: flex-end;
  margin-right: 3rem;

  ${media.greaterThan('medium')`
    display: none;
  `}
  > *:not(:last-child) {
    margin-right: ${V.Space.xs};
  }
`

export const Title = styled.h1`
  color: var(--shellText);
  display: flex;
  letter-spacing: .025rem;
  font-family: monospace;
  font-size: 1.7rem;
  font-weight: 600;

  &:first-letter { text-transform: uppercase; }

  &.blinker:after {
    content: '_';
    display: inline-block;
    animation: blink .8s infinite;
    font-weight: bold;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${media.lessThan('350px')`
    display: none;
  `}
  /*${media.lessThan('medium')`
    font-size: 1.7rem;
  `}
  ${media.greaterThan('small')`
    font-size: 1.7rem;
  `}*/
`

export const MenuBarItem = styled.span`
  color: var(--shellText);
  cursor: pointer;
  display: block;
  height: 3.75rem;
  padding: 1.1rem;
  position: relative;
  width: 3.75rem;
  
  &.light {
    color: var(--greetings);
    
    &:hover {
      color: #222;
    }
  }

  &:hover {
    color: var(--greetings);
  }

  &.display {
    ${media.lessThan("medium")`
      display: none;
    `}
  }
  ${media.greaterThan("large")`
    &:hover {
      color: var(--greetings);
    }
  `}
  ${media.lessThan("medium")`
    height: 3.2rem;
    padding: .9rem;
    position: relative;
    width: 3.2rem;
  `}
`

export const MenuBarLink = styled(AniLink)`
  display: block;

  &.active {
    span {
      color: var(--highlight);
    }
  }
`